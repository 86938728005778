import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { newbitEnv, envIsBuild } from '../../env'

export function initSentry() {
  if (envIsBuild && ['dev', 'test', 'production'].includes(newbitEnv)) {
    Sentry.init({
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'ResizeObserver loop limit exceeded',
        `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
        `Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
      ],
      dsn: 'https://fd682f3cfbcd65d3931ee32d87da1429@o4506992819568640.ingest.us.sentry.io/4506993216782336',
      integrations: [new BrowserTracing()],
      environment: newbitEnv,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })
  }
}
