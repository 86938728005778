/** 风控告警提醒 */
import AssetsPopupTips from '@/features/assets/common/assets-popup/assets-popup-tips'
import { t } from '@lingui/macro'
import { useCommonStore } from '@/store/common'
import { RiskControlTypeEnum } from '@/constants/risk-control'
import { Button } from '@nbit/arco'
import { useEffect, useState } from 'react'

interface IRiskControlPopupProps {
  visible: boolean
  setVisible?: (val: boolean) => void
}

function RiskControlPopup(props: IRiskControlPopupProps) {
  const { visible } = props
  const { riskControlData, setRiskControlData } = useCommonStore()
  const { code: riskCode, msg: riskMsg } = riskControlData || {}
  const [isShow, setIsShow] = useState(false)

  const onOkClick = () => {
    setRiskControlData(null)
    setIsShow(false)
  }

  useEffect(() => {
    if (riskCode === RiskControlTypeEnum.riskControlLv1) setIsShow(true)
  }, [riskCode])

  if (!isShow) return null

  return (
    <AssetsPopupTips
      footer={null}
      visible={visible}
      setVisible={onOkClick}
      maskClosable={false}
      closeIcon={null}
      slotContent={
        <div>
          <div className="flex justify-center">{riskMsg}</div>
          <div className="footer">
            <Button type="primary" onClick={onOkClick}>
              {t`features_trade_spot_index_2510`}
            </Button>
          </div>
        </div>
      }
      okText={t`features_trade_spot_index_2510`}
    />
  )
}

export default RiskControlPopup
